import React, { useState } from "react";

import styles from './form.module.scss';

const HeaderFormLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.headline}>Login Cloud</h2>
      <form className={styles.form} acceptCharset="utf-8" action="https://mur.digi-bel.de/u/login" method="post">
        <div>
          <input name="_method" type="hidden" value="POST"/>
        </div>
        <div className={styles.group}>
          <input className={styles.input} data-empty={username === ""} name="username" type="text" defaultValue={username} onChange={(e)=>{setUsername(e.target.value)}}/>
          <label className={styles.label} htmlFor="username">Benutzername</label>
        </div>

        <div className={styles.group}>
          <input className={styles.input} data-empty={password === ""} name="password" type="password" defaultValue={password} onChange={(e)=>{setPassword(e.target.value)}}/>
          <label className={styles.label} htmlFor="password">Passwort</label>
        </div>
        <button className={styles.btn} type="submit">Anmelden</button>
      </form>
    </div>
  )
}

export default HeaderFormLogin;
