function pathTo(resource) {
  switch (resource.__typename) {
    case 'ContentfulJob':
      return `/karriere#${resource.slug}`;
    case 'ContentfulPost':
      return `/magazin/${resource.slug}`;
    default:
      return resource.slug === '/' ? '/' : `/${resource.slug}`;
  }
}

module.exports.pathTo = pathTo;
