import React, { useState } from 'react'

import { Link } from 'gatsby'
import Modal from '../modal'
import styles from './index.module.scss'

const BANNER_HIDDEN_KEY = 'gatsby-gdpr-hide-cookie-banner'
const ESSENTIALS_KEY = 'gatsby-gdpr-allow-essential-cookies'
const GOOGLE_ANALYTICS_KEY = 'gatsby-gdpr-google-analytics'

// write a function that sets cookies
// write a function that gets cookies

const CookieBanner = (props) => {
  // do not ssr
  if (typeof document === 'undefined') return null
  return <CookieBannerComponent {...props} />
}

const CookieBannerComponent = () => {
  const [allowGoogleAnalytics, setAllowGoogleAnalytics] = useState(true)
  const [showDetails, setShowDetails] = useState(false)
  const [updated, setUpdated] = useState(false)

  const availableCookies = [BANNER_HIDDEN_KEY, ESSENTIALS_KEY, GOOGLE_ANALYTICS_KEY]

  const getCookie = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  }

  const hideBanner = getCookie(BANNER_HIDDEN_KEY) === 'true' && !availableCookies.map(cookie => !!getCookie(cookie)).includes(false)

  // hide the banner if there is a cookie and it is true
  if (hideBanner) return null

  const confirmCookies = () => {
    setCookie(BANNER_HIDDEN_KEY, "true", 90)
    setCookie(ESSENTIALS_KEY, "true", 90)
    setCookie(GOOGLE_ANALYTICS_KEY, allowGoogleAnalytics.toString(), 90)
    setUpdated(true)
  }

  const disallowCookies = () => {
    setCookie(BANNER_HIDDEN_KEY, "true", 90)
    setCookie(ESSENTIALS_KEY, "true", 90)
    setCookie(GOOGLE_ANALYTICS_KEY, "false", 90)
    setUpdated(true)
  }

  return (
    <Modal isOpen={true} canClose={false} canLockBody={false}>
      <React.Fragment />
      <div className={styles.content}>
        <h2 className={styles.headline}>Wir verwenden Cookies</h2>
        <div className={styles.message}>Diese Webseite verwendet Cookies, die notwendig sind, um die Webseite zu nutzen. Weiterhin verwenden wir Dienste von Drittanbietern, die uns helfen, unsere Website zu verbessern (Website-Optmierung). Für die Verwendung bestimmter Dienste, benötigen wir Ihre Einwilligung. Die Einwilligung können Sie jederzeit widerrufen oder anpassen. Weitere Informationen finden Sie in unserer <Link to='/datenschutz'>Datenschutzerklärung</Link> oder im <Link to='/impressum'>Impressum</Link>.</div>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={confirmCookies}>Zustimmen</button>
          <button className={styles.buttonSecondary} onClick={disallowCookies}>Ablehnen</button>
        </div>
      </div>
    </Modal>
  )
}

export default CookieBanner

function setCookie(name, value, days) {
  let expires = ""
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = "; expires=" + date.toUTCString()
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/"
}